.side-search-tab, .side-apply-tab {
  position: fixed;
  display: block;
  padding: .75em 1em .75em 1em;
  font-size: 20px;
  color: white;
  width: 60px;
  transition: right 0.1s;
  z-index: 100;
}


.side-search-tab{
  top:95px + $frame-width-xs;
  right: 0;
  /* background-color: $brand-secondary; */
  &:hover, &:focus {
    text-decoration: none;
    color: white;
    right: 0;
  }
  span{
    display: none;
  }
}

.side-apply-tab{
  display: none;
}


@include breakpoint("sm") {
  .side-search-tab, .side-apply-tab {
    padding: .75em 1em .75em 1em;
    width: 145px;
    white-space: nowrap;
  }

  .side-search-tab {
    top: 140px + $frame-width-sm;
    right: -5px;
    span {
      display: inline;
    }
  }
}
@include breakpoint("md") {
  .side-search-tab {
    top: 160px + $frame-width-md;
  }
  // Only show this tab for home site programs site.
  .og-context-node-266.root_space_id_2507 .side-apply-tab {
    display: block;
    top: 240px + $frame-width-md;
    right: -5px;
    /* background-color: $brand-secondary; */
    &:hover, &:focus {
      text-decoration: none;
      color: white;
      right: 0;
    }
  }
}
