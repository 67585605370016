// Styles for Image CTA grid
// Areas of study to start, but todo should refactor to be more generic
// --------------------------------------------------

.progAreaGrid__link{
  display: block;
  position: relative;
  margin-bottom: 30px;
  &:hover .progAreaGrid__image:before{
    opacity: .8;
    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
  }
}
.progAreaGrid__image{
  &:before{
    content:"";
    position: absolute;
    top:0; bottom:0; left:0; right:0;
    background: $brand-primary;
    opacity: .65;
    transition: opacity 0.25s ease, background 0.25s ease;
  }
}
.progAreaGrid__title{
  position: absolute;
  bottom:.5em;
  left:1em;
  padding-right:1em;
  color:white;
  text-shadow:0px 0px 2em rgba(0,0,0,0.75);
  font-size: 1.2em;
}