// Taken from custom Deepbay social icons
.viuFollow{
  margin-top: 1em;
  text-align: center;
  a:after{
    content: none !important;
  }
  .viu-social__socialIcon{
    font-size: 40px;
    margin: 0 1em;
  }
}
