// Styles for parallax full page blocks
// --------------------------------------------------

// Spacing between paragraphs etc.
.field-body{
  //margin-bottom: 2.5em;
}
.pane-node-body{
  //padding-bottom: 1em;
  margin-bottom: 1em;
}
.panel-group{
  padding-bottom: 1.0em;
}
.paragraphs-items-field-content-paragraphs{
  > .entity-paragraphs-item{
    margin-top:1.0em;
    margin-bottom:1.0em;
    padding-top:0;
    padding-bottom:0;
  }
  > .entity-paragraphs-item:first-child{
    //margin-top:0;
    //padding-top:0;
  }
  > .section-spacing{
    margin-top:1.0em;
  }
  // No margin between subsequent sections.
  > .section-spacing + .section-spacing,
  > .section-spacing:first-child{
    margin-top:0 !important;
  }
  .panel-group{
    margin-bottom: 0;
  }
  h1,h2,h3{
    &:first-child{
      margin-top:0;
    }
  }
}

// Allow no gap for fullscreen hero but keep
// the extra spacing around the first child section.
/*
.fullscreen-hero .oa-flex-grow{
  margin-top: -2em;
  .content-canvas .content-canvas-inner{
    background-color:transparent;
  }
}
*/


.page-section__content__inner{
  @include make-xs-column(11);
  @include make-xs-column-offset(1);
  @include make-sm-column(9);
  @include make-sm-column-offset(2);
  @include make-md-column(8);
  @include make-md-column-offset(2);

  @include breakpoint("xs"){
    padding-right:25px; //override so the content doesn't touch the edge
  }

  z-index:2;
}

.page-section{
  @include breakpoint("xs"){
    // additional padding here on mobile
    padding-left: 24px;
    padding-right: 24px;
  }
  position: relative;
  z-index:1;
  //Fix for IE.
  display: flex;
  flex-direction:column;
}

.parallax-combo__inner{
  width:100%;
}

.page-section__bg {
  position: absolute;
  width:100%;
  height:100%;
  top:0;
  left: 0px;
  z-index:1;
  opacity:0.5;
  @include breakpoint("sm"){
    opacity:0.7;
  }
  overflow:hidden;
}
.page-section--no-dim .page-section__bg{
  opacity:1;
  @include breakpoint("sm"){
    opacity:1;
  }
}

.page-section--hero:not(.px--text-light):not(.px--text-dark) .page-section__bg{
  // No text over the hero
  opacity:1;
}

@include breakpoint("md"){
  .page-section--pin .page-section__bg,
  .page-section--bg-image.page-section--pull .page-section__bg{
    opacity:1;
    &:before {
      opacity:0;
      transition: opacity 1s;
      content:"";
      position: absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;
    }
    &.dim:before{
      opacity:1;
    }
  }
  .page-section--pin .page-section__bg:before{
    background-color: rgba(0,0,0,0.4);
  }
  .page-section--pin.page-section--color-3 .page-section__bg:before{
    background: rgba(255,255,255,0.4);
  }
  .page-section--pin .page-section__bg:before{
    background-color: rgba(0,0,0,0.4);
  }
  .page-section--pin.page-section--color-3 .page-section__bg:before{
    background: rgba(255,255,255,0.4);
  }

  @media only screen and (orientation: landscape){

    .page-section--pin,.page-section--bg-image{
      &.page-section--pull-right .page-section__bg:before{
        /* https://www.cssmatic.com/gradient-generator#'\-moz\-linear\-gradient\%28left\%2C\%20rgba\%280\%2C0\%2C0\%2C0\%29\%2044\%25\%2C\%20rgba\%280\%2C0\%2C0\%2C0\.66\%29\%2064\%25\%29\%3B' */
        background-color: rgba(0,0,0,0.3);
        background: -moz-linear-gradient(left, rgba(0,0,0,0) 44%, rgba(0,0,0,0.66) 64%);
        background: -webkit-gradient(left top, right top, color-stop(44%, rgba(0,0,0,0)), color-stop(64%, rgba(0,0,0,0.66)));
        background: -webkit-linear-gradient(left, rgba(0,0,0,0) 44%, rgba(0,0,0,0.66) 64%);
        background: -o-linear-gradient(left, rgba(0,0,0,0) 44%, rgba(0,0,0,0.66) 64%);
        background: -ms-linear-gradient(left, rgba(0,0,0,0) 44%, rgba(0,0,0,0.66) 64%);
        background: linear-gradient(to right, rgba(0,0,0,0) 44%, rgba(0,0,0,0.66) 64%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
      }
      &.page-section--pull-left .page-section__bg:before{
        background-color: rgba(0,0,0,0.3);
        background: -moz-linear-gradient(left, rgba(0,0,0,0.66) 36%, rgba(0,0,0,0) 56%);
        background: -webkit-gradient(left top, right top, color-stop(36%, rgba(0,0,0,0.66)), color-stop(56%, rgba(0,0,0,0)));
        background: -webkit-linear-gradient(left, rgba(0,0,0,0.66) 36%, rgba(0,0,0,0) 56%);
        background: -o-linear-gradient(left, rgba(0,0,0,0.66) 36%, rgba(0,0,0,0) 56%);
        background: -ms-linear-gradient(left, rgba(0,0,0,0.66) 36%, rgba(0,0,0,0) 56%);
        background: linear-gradient(to right, rgba(0,0,0,0.66) 36%, rgba(0,0,0,0) 56%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
      }
      &.page-section--pull-right.page-section--color-3 .page-section__bg:before{
        /* https://www.cssmatic.com/gradient-generator#'\-moz\-linear\-gradient\%28left\%2C\%20rgba\%280\%2C0\%2C0\%2C0\%29\%2044\%25\%2C\%20rgba\%280\%2C0\%2C0\%2C0\.66\%29\%2064\%25\%29\%3B' */
        background: rgba(255,255,255,0.3);
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 44%, rgba(255,255,255,0.66) 64%);
        background: -webkit-gradient(left top, right top, color-stop(44%, rgba(255,255,255,0)), color-stop(64%, rgba(255,255,255,0.66)));
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 44%, rgba(255,255,255,0.66) 64%);
        background: -o-linear-gradient(left, rgba(255,255,255,0) 44%, rgba(255,255,255,0.66) 64%);
        background: -ms-linear-gradient(left, rgba(255,255,255,0) 44%, rgba(255,255,255,0.66) 64%);
        background: linear-gradient(to right, rgba(255,255,255,0) 44%, rgba(255,255,255,0.66) 64%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
      }
      &.page-section--pull-left.page-section--color-3 .page-section__bg:before{
        background: rgba(255,255,255,0.3);
        background: -moz-linear-gradient(left, rgba(255,255,255,0.66) 36%, rgba(255,255,255,0) 56%);
        background: -webkit-gradient(left top, right top, color-stop(36%, rgba(255,255,255,0.66)), color-stop(56%, rgba(255,255,255,0)));
        background: -webkit-linear-gradient(left, rgba(255,255,255,0.66) 36%, rgba(255,255,255,0) 56%);
        background: -o-linear-gradient(left, rgba(255,255,255,0.66) 36%, rgba(255,255,255,0) 56%);
        background: -ms-linear-gradient(left, rgba(255,255,255,0.66) 36%, rgba(255,255,255,0) 56%);
        background: linear-gradient(to right, rgba(255,255,255,0.66) 36%, rgba(255,255,255,0) 56%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
      }
    }

  }
}


.page-section--parallax .page-section__bg{
  // Currently only parallax on larger devices
  @include breakpoint("sm"){
    height:130%;
  }
}
.page-section--parallax.page-section--full-height .page-section__bg{
  // works for fixed pinning
  //height:140vh;
}

.page-section__content {
  position: relative;
  padding-top:2em;
  padding-bottom:2em;
  z-index: 2;
}

.page-section__content__center {
  width:100%;
  // IE 11
  min-height:1px;
}

.page-section .page-section__content {
  display: flex;
  align-items: center;
  flex-direction:column;
  justify-content: center;
  flex: 1 1 auto;
  // IE 11
  min-height:1px;
}


// Modifiers

.page-section--center{
  .page-section__content__inner{
    text-align:center;
  }
}

.page-section--pull-left{
  .page-section__content__inner{
    @include make-md-column(4);
    @include make-md-column-offset(2);
  }
}

.page-section--pull-right{
  .page-section__content__inner{
    @include make-md-column(4);
    @include make-md-column-offset(7);
  }
}

.page-section--parallax {
  //@include full-width;
  @include viewport-unit(min-height, 65vh);
  .page-section__content{
    @include viewport-unit(min-height, 65vh);
  }
}

// these could overflow so they need to be min-height 100vh
.page-section--full-height{
  //min-height:100vh;
  @include viewport-unit(min-height, 100vh);
  .page-section__content {
    //min-height: 100vh;
    @include viewport-unit(min-height, 100vh);
  }
}

.page-section--reduced-padding {
  .page-section__content {
    padding-top: 1em;
    padding-bottom: 1em;
    @include breakpoint("sm") {
      padding-top: 2em;
      padding-bottom: 2em;
    }
  }
}

.page-section--no-padding {
  .page-section__content {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
.page-section--reduced-padding-top {
  .page-section__content {
    padding-top: 1em;
    @include breakpoint("sm") {
      padding-top: 2em;
    }
  }
}

.page-section--no-padding-top {
  .page-section__content {
    padding-top: 1em;
  }
}

.page-section--reduced-padding-bottom {
  .page-section__content {
    padding-bottom: 1em;
    @include breakpoint("sm") {
      padding-bottom: 2em;
    }
  }
}

.page-section--no-padding-bottom {
  .page-section__content {
    padding-bottom: 1em;
  }
}
.page-section--parallax{
  &.px--text-light .page-section__content{
    text-shadow: 0px 0px 2em rgba(0, 0, 0, .85);
  }
  &.px--text-dark .page-section__content{
    text-shadow: 0px 0px 2em rgba(255, 255, 255, .85);
  }
}

// Page section combo parallax/content

.page-section--parallax-combo{
  //@include full-width;
  //min-height: 100vh;
  @include viewport-unit(min-height, 100vh);
}
.page-section.page-section--parallax-combo{
  padding:0;
}

.parallax-combo__heading_large{
  font-size: 30px;
  @include breakpoint('sm'){
    font-size:50px;
  }
  @include breakpoint('md'){
    font-size:60px;
  }
}


@include breakpoint("sm"){

  .page-section__content{
    padding-top:100px;
    padding-bottom:100px;
  }

  .parallax-combo{
    .page-section--parallax{
      .page-section__content{
        text-align:center;
      }
    }
    .page-section__content{
      padding:50px 0;
    }
    .parallax-combo__content{
      //min-height:40vh;
      @include viewport-unit(min-height, 35vh);
      @include vertical-center;
    }
  }
}


// Scrollmagic override

.scrollmagic-pin-spacer{
  //override display flex which messes up my expanded sections
  display: block !important;
}

.page-section__bg-clip{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.page-section__bg picture img{
  height:100%;
  width:100%;
  object-fit: cover;
  // Below is part of a polyfill for IE for this property. https://github.com/bfred-it/object-fit-images
  font-family: 'object-fit: cover;';
}

// VIU plain text section

.paragraphs-item-viu-text-section{
  padding: 30px 0;
}

// A little help for the stock OA text paragraph

.field-paragraph-text{
  margin-top: 3em;
}


// Map Section

.page-section--map{
  min-height: 65vh;
  iframe{
    min-height: 65vh;
  }
}

// Youtube Section

.page-section--youtube{
  min-height: 65vh;
  .player{
    min-height: 65vh;
  }
  iframe{
    width:100%;
    min-height: 65vh;
    height:100%;
  }
}

/* Text and Image */
.page-section--text-and-image {
  .page-section__content__inner{
    @include make-xs-column(8);
    @include make-xs-column-offset(2);
    @include make-sm-column(8);
    @include make-sm-column-offset(2);
    @include make-md-column(10);
    @include make-md-column-offset(1);
    @include breakpoint('md') {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  &.page-section--text-image-pull-right .page-section__content__inner{
    @include breakpoint('md') {
      flex-direction: row;
    }
  }
  &.page-section--text-image-center .page-section__content__inner{
    text-align: center;
  }
}

.page-section--text-and-image__image {
  @include breakpoint('md') {
    flex: 0 0 350px;
  }
  img{
    display: block;
    border-radius: 50%;
    max-width: 80%;
    margin: 0 auto;
  }
  margin-bottom: 2rem;
  padding-right: 0;
  @include breakpoint('md') {
    img {
      max-width: 100%;
    }
    margin-left: 3em;
    max-width: 350px;
  }
  .page-section--text-image-pull-right & {
    @include breakpoint('md') {
      margin-left: 0;
      margin-right: 3em;
    }
  }
}


.page-section__button {
  .cta-button {
    display: inline-block;
    margin-right: 0.5em;
  }
}
