
html, body {
  height:auto;
  min-height: 100%;
}
/*
body.oa-responsive-region-left-on {
  padding-left: 42px - $frame-width-xs;
  @include breakpoint("sm"){
    padding-left: 42px - $frame-width-sm;
  }
  @include breakpoint("md"){
    padding-left: 42px - $frame-width-md;
  }
}

.panel-display .oa-responsive-desktop.oa-responsive-region-left {
  background: rgba(48, 48, 48, 1);
}
*/

.oa-fullwidth{
  margin-left: $grid-gutter-width-xs / -2;
  margin-right: $grid-gutter-width-xs / -2;
  @include breakpoint("sm"){
    margin-left: $grid-gutter-width-sm / -2;
    margin-right: $grid-gutter-width-sm / -2;
  }
  @include breakpoint("md"){
    margin-left: $grid-gutter-width-md / -2;
    margin-right: $grid-gutter-width-md / -2;
  }
  @include breakpoint("lg"){
    margin-left: $grid-gutter-width-lg / -2;
    margin-right: $grid-gutter-width-lg / -2;
  }
}

body .container-fluid #footer{
  // override hardcoded OA margins based on default 30px gutter
  @include  make-row();
}

.row.oa-responsive-region-row-left.oa-responsive-region-row-right {
  overflow: hidden;
}

.panel-display .oa-responsive-desktop.oa-responsive-region-left {
  background: $brand-secondary;
}

// Ensure OA top bar makes room for audience nav

.oa-toolbar-style.navbar{
  z-index: 999;
  @include breakpoint("md"){
    margin-top:$frame-width-md;
  }
}

@include breakpoint("md"){
  // Move left toolbar down to accommodate audience nav
  .navbar .oa-responsive-regions-toggle-left{
    top: 16px + $frame-width-md !important;
  }
  body.oa-responsive-region-left-on .panel-display .oa-responsive-desktop.oa-responsive-region-left, .panel-display .oa-responsive-desktop.oa-responsive-region-left.oa-responsive-expand{
    top:$frame-width-md;
  }
}

ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}

.panel-pane.pane-views-panes, .panel-pane{
  border-right:0 !important;
  &:after{
    border:0 !important;
  }
}