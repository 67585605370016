// Styles for Nodes
// --------------------------------------------------

.node-oa-worktracker-task {
  h2.title.comment-form {
    margin-top: 1em;
  }
}

.oa-event-date-month-wrapper {
  background: #40A03A;
}
