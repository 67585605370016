// Styles for Forms
// --------------------------------------------------

#node-edit .form-actions input {
  background-color: white;
  color: #333;
  border-color: #C1C1C1;
}

form .form-actions {
  background-color: transparent;
  .btn-default{
    display:inline-block;
  }
}

.form-control{
  font-size:1em;
  height:auto;
}

input[type="text"],input[type="email"], input[type="password"], .ui-autocomplete-input, textarea, .uneditable-input{
  padding: 0.5em 1em;
  font-size:1em;
}



// Chosen overrides
.chosen-container{
  width:100% !important;
}

.chosen-container.form-control{
  font-size:.8em;
  box-shadow:none;
}
.chosen-container .chosen-choices{
  background-image:none;
  border-radius:8px;
  padding:.3em;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  height: 100%;
}

// Search choice (selected)
.chosen-container-multi .chosen-choices li.search-choice{
  background: $brand-secondary;
  color:white;
  border:0;
  padding: 8px 25px 8px 10px;
  box-shadow:none;
  border-radius:8px;
  .search-choice-close{
    background:none;
    font-size:1.1em;
    right:6px;
    top: 50%;
    transform: translateY(-50%);
    &:before{
      content:"X";
      font-weight:bold;
      color:white;
    }
  }
}

// Chosen dropdown items
.chosen-container .chosen-results li.highlighted{
  background: $brand-secondary;
}

// Puts 'filter' button on the right and both buttons on newline
.view-curriculum-approval {
  .views-submit-button {
    clear:both;
  }
  .views-reset-button, .views-submit-button {
    float: right;
    padding: .5em .5em 0 0;
  }
}

/*  WEBFORM CHANGES */

// Override webform inline fields from the oa_basetheme styles
.webform-container-inline .form-control{
  display: inline-block;
  width: auto;
}
// Z-index fix for webform popup calendar
#ui-datepicker-div{
  z-index: 4 !important;
}

// Increase margin between components a bit.
form .webform-component {
  margin: 1em 0;
  .form-textarea-wrapper{
    margin-bottom: 1.5em;
  }
}

// Increase size of fieldset headings.
.webform-component-fieldset .fieldset-legend {
  font-weight: bold;
  font-size: 2rem;
}
