// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

// [converter] This is defined recursively in LESS, but Sass supports real loops

// CUSTOMIZED BY VIU for 3 different responsive gutter widths as dictated by Apero designs.



@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding

    padding-left:  ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));

    @media (min-width: $screen-sm-min) {
      padding-left: ceil(($grid-gutter-width-sm / 2));
      padding-right: floor(($grid-gutter-width-sm / 2));
    }
    @media (min-width: $screen-md-min) {
      padding-left: ceil(($grid-gutter-width-md / 2));
      padding-right: floor(($grid-gutter-width-md / 2));
    }
    @media (min-width: $screen-lg-min) {
      padding-left: ceil(($grid-gutter-width-lg / 2));
      padding-right: floor(($grid-gutter-width-lg / 2));
    }
  }
}



