// VIU Branding

.viu-brand{
  @include make-xs-column(2);
  //@include make-sm-column(1);
  @include make-md-column(1);
  top:20px;
  z-index:2;
  @include breakpoint("sm"){
    top:60px;
  }
  @include breakpoint("md"){
    top:75px;
  }
}

// For now at least, remove on admin pages
.page-admin .viu-brand{
  display: none;
}

.viu-logo-link{
  pointer-events: all;
}

.viu-logo{
  width:100%;

  //tablet designs have logo straddling two columns.
  @include breakpoint("sm"){
    position: relative;
    width:60%;
    left:20%;
  }
  @include breakpoint("md"){
    width:100%;
    left:0;
  }
}

.logo-grid-container--fixed{
  position: fixed;
  pointer-events: none;
  width: calc(100% - #{$frame-width-xs} * 2);
  top: $frame-width-xs;
  z-index:101;

  @include clearfix();

  @include breakpoint("sm"){
    width: calc(100% - #{$frame-width-sm} * 2);
    top: $frame-width-sm;
  }
  @include breakpoint("md"){
    width: calc(100% - #{$frame-width-md} * 2);
    top: $frame-width-md;
  }
}


