// Styles for VIU global CTAs
// --------------------------------------------------

.globalCTAs {
  z-index: 1;
  position: relative;
}

.globalCTAs .page-section__content__inner{
  @include make-xs-column(8);
  @include make-xs-column-offset(2);
  @include make-sm-column(8);
  @include make-sm-column-offset(2);
  @include make-md-column(10);
  @include make-md-column-offset(1);
}

.globalCTAs.cols-2 .page-section__content__inner{
  @include make-xs-column(8);
  @include make-xs-column-offset(2);
  @include make-sm-column(9);
  @include make-sm-column-offset(2);
  @include make-md-column(8);
  @include make-md-column-offset(2);
}



.globalCTAs__icon, .globalCTAs__intro{
  display: none;
}

.globalCTAs__cta {
  display: block;
  text-align: center;
  padding-right: 2em;
  p {
    margin-bottom:1em;
  }
}
.globalCTAs__heading{
  font-size:1.5em;
}

@include breakpoint("xs"){
  .cta-button.globalCTAs__link {
    width:80%;
    margin-left:auto;
    margin-right:auto;
  }
}

@include breakpoint("sm") {
  .globalCTAs__cta {
    text-align: center;
    margin-bottom: 2em;
  }
  .globalCTAs__icon {
    display: block;
    font-size: 3em;
    line-height: 1.5em;
  }
  .globalCTAs__intro{
    display: inline;
    strong{
      display: block;
    }
  }

  /* TODO - top selector to be deprecated */
  .pane-viu-global-ctas .globalCTAs__link.cta-button,
  .globalCTAs--centered .globalCTAs__link.cta-button{
    margin-left: auto;
    margin-right: auto;
  }
}


@include breakpoint("md") {

  .globalCTAs__container {
    padding: 0;
  }

  .globalCTAs__icon {
    font-size: 4em;
  }

  .globalCTAs__cta {
    border: 0;
    margin-bottom: 0;
    &:hover, &:active, &:focus {
      background: none;
      text-decoration: none;
    }
  }

  .globalCTAs__link {
    margin: 0;
  }

}

/* Flex CTAs left aligned */
.globalCTAs--flex .globalCTAs__cta{
  text-align:left;
}

/* Flex CTAs center aligned */
.globalCTAs--flex.ctas--text-centered .globalCTAs__cta{
  text-align:center;
  .globalCTAs__link{
    margin-left:auto;
    margin-right:auto;
  }
}

.globalCTAs--flex, .image-block-ctas{
  &.cols-3{
    .globalCTAs__cta, .image-block-ctas__cta{
      @include make-md-column(4);
    }
  }
  &.cols-2{
    .globalCTAs__cta, .image-block-ctas__cta{
      @include make-md-column(6);
    }
  }
  &.cols-1{
    .globalCTAs__cta, .image-block-ctas__cta{
      @include make-md-column(12);
    }
  }
}

/* Flex CTAs multi-row */

.globalCTAs--flex.cols-3 .globalCTAs__cta:nth-child(3n+1),
.globalCTAs--flex.cols-2 .globalCTAs__cta:nth-child(2n+1),
.globalCTAs--flex.col-1 .globalCTAs__cta:nth-child(1n+1){
  clear:left;
}

.globalCTAs--flex.cols-3 .globalCTAs__cta:nth-child(n+4),
.globalCTAs--flex.cols-2 .globalCTAs__cta:nth-child(n+3),
{
  margin-top: 3em;
}
.globalCTAs--flex.col-1 .globalCTAs__cta{
  margin-top: 1em;
  margin-bottom: 2em;
}

.globalCTAs--flex__heading{
  margin-bottom: 1em;
  text-align: center;
  font-size: 3em;
}
.image-block-ctas__heading{
  margin-bottom: 1em;
  text-align: center;
  font-size: 2em;
}
.globalCTAs--flex .globalCTAs__cta--has-image{
  text-align: center;
}
.globalCTAs__cta--has-image .globalCTAs__link{
  margin-left:auto;
  margin-right:auto;
}

.globalCTAs__cta__image{
  display: none;
  @include breakpoint("md") {
    display: block;
    border-radius:50%;
    max-width:80%;
    margin: 0 auto;
  }
}
