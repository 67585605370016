.viu_program_quicksearch{
  position: relative;
  input{
    padding:0.4em 1em;
    height:auto;
  }
  button{
    border:0;
    background: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right:.5em;
    color: $brand-secondary;
  }
}


.viuNavigation__search{
  font-size:.75em;
  position: relative;
  margin-bottom:2em;
  input{
    background:transparent;
    border: 1px solid white;
    color:white;
    border-radius:500px;
    text-align: center;
    font-size: 1em;
    &::placeholder{
      color:white;
    }
  }
  button{
    color:white;
  }

}