//
// Base HTML elements
// --------------------------------------------------
/*
// This used to be in panopoly_images but was removed and told to put into theme
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

// This used to be in compass_radix to override panopoly_images
.field img.panopoly-image-full,
.field img.panopoly-image-half,
.field img.panopoly-image-quarter {
  max-width: 100%;
  width: auto;
  height: auto;
  float: none;
  margin: 0;
}

// reduce list padding
ul, ol{
  padding-left: 20px;
}
*/


// add icons to document links
$file-exts: "pdf" "doc" "docx" "xls" "xlsx" "ppt" "pot" "dot";

@each $file-ext in $file-exts{
  a[href $='.#{$file-ext}']:before{
    font-family: FontAwesome;
    display: inline-block;
    padding-left: 0.1em;
    padding-right: 0.2em;
  }
}

a[href $='.pdf']:before {
  content: "\f1c1";
}

a[href $='.doc']:before, a[href $='.dot']:before, a[href $='.docx']:before {
  content: "\f1c2";
}

a[href $='.xls']:before, a[href $='.xlsx']:before {
  content: "\f1c3";
}

a[href $='.ppt']:before, a[href $='.pot']:before {
  content: "\f1c4";
}

.content a[href*="//"]:not([href*="viu.ca"]):after,
#nav-site a[href*="//"]:not([href*="viu.ca"]):after
{
  font-family: FontAwesome;
  display: inline-block;
  padding-left: 0.2em;
  padding-right: 0.1em;
  content: "\f08e";
  font-size: 0.7em;
  vertical-align: super;
}

a.img-link:before,
a.img-link:after, #nav-site a.img-link[href*="//"]:not([href*="viu.ca"]):after, .content a.img-link[href*="//"]:not([href*="viu.ca"]):after{
  content:none;
}

.hideBeforeText:before {
  content:none !important;
}

// At least keep iframe from busting out
iframe{
  max-width:100%;
}

// Hide any residual icons that exist.
img[src*="icon.gif"].media-image{
  display: none;
}
