// Bootstrap panel styles
// --------------------------------------------------

.panel{
  box-shadow:none;
  border-width:0;
}

.panel-default > .panel-heading{
  background:transparent;
  border:0;
  border-bottom: 1px solid #D2D1D1;
  .pane-title{
    color:$brand-primary;
  }
}
