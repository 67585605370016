// Tab-to-accordion styles.
// Add .tabCollapse to the tabs of a regular Bootstrap 3.x tab div to use
// --------------------------------------------------

.tabCollapse{
  @include clearfix;
  margin-bottom:3em;
}

// Tab Mode

.nav-tabs--tabCollapse{
  float:left;
  width:30%;
  border:0;
  border-right:1px solid #ededed;

  > li{
    float:none;
    border-bottom:1px solid #ededed;
    a{
      margin-right:0;
      border-radius:0;
      border:0;
    }
    &.active a, &.active a:hover, &.active a:focus{
      border:0;
      background: rgba(0,0,0,.05);
    }

  }
}

.tab-content--tabCollapse{
  float:left;
  padding-left:2em;
  width:70%;
}


// Accordion Mode
.panel-group, .viu-collapsible{
  .panel-heading{
    .panel-title a{
      &:focus, &:hover{
        text-decoration: none;
      }
      &:before{
        content: "\f0dd ";
        font-family: "Fontawesome";
        margin-right: .5em;
      }
      &.collapsed:before{
        content: "\f0da ";
      }
    }
  }
}