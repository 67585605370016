// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element

// CUSTOMIZED BY VIU for 3 different responsive gutter widths as dictated by Apero designs.


@mixin container-fixed($gutter: $grid-gutter-width-lg) {
  margin-right: auto;
  margin-left: auto;

  padding-left: ceil(($grid-gutter-width-xs / 2));
  padding-right: floor(($grid-gutter-width-xs / 2));

  @media (min-width: $screen-sm-min) {
    padding-left: ceil(($grid-gutter-width-sm / 2));
    padding-right: floor(($grid-gutter-width-sm / 2));
  }
  @media (min-width: $screen-md-min) {
    padding-left:  ceil(($grid-gutter-width-md / 2));
    padding-right: floor(($grid-gutter-width-md / 2));
  }
  @media (min-width: $screen-lg-min) {
    padding-left:  ceil(($grid-gutter-width-lg / 2));
    padding-right: floor(($grid-gutter-width-lg / 2));
  }
  @include clearfix;
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {

  margin-left:  ceil(($gutter / -2));
  margin-right: floor(($gutter / -2));
  @include clearfix;

  @media (min-width: $screen-sm-min) {
    margin-left:  ceil(($grid-gutter-width-sm / -2));
    margin-right: floor(($grid-gutter-width-sm / -2));
  }
  @media (min-width: $screen-md-min) {
    margin-left:  ceil(($grid-gutter-width-md / -2));
    margin-right: floor(($grid-gutter-width-md / -2));
  }
  @media (min-width: $screen-lg-min) {
    margin-left:  ceil(($grid-gutter-width-lg / -2));
    margin-right: floor(($grid-gutter-width-lg / -2));
  }
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width-xs) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
}
@mixin make-xs-column-offset($columns) {
  margin-left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-push($columns) {
  left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-pull($columns) {
  right: percentage(($columns / $grid-columns));
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width-sm) {
  position: relative;
  min-height: 1px;
  padding-left:  ($grid-gutter-width-xs / 2);
  padding-right: ($grid-gutter-width-xs / 2);

  @media (min-width: $screen-sm-min) {
    padding-left:  ($grid-gutter-width-sm / 2);
    padding-right: ($grid-gutter-width-sm / 2);
    float: left;
    width: percentage(($columns / $grid-columns));
  }
  @media (min-width: $screen-md-min) {
    padding-left:  ($grid-gutter-width-md / 2);
    padding-right: ($grid-gutter-width-md / 2);
  }
  @media (min-width: $screen-lg-min) {
    padding-left:  ($grid-gutter-width-lg / 2);
    padding-right: ($grid-gutter-width-lg / 2);
  }
}
@mixin make-sm-column-offset($columns) {
  @media (min-width: $screen-sm-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-push($columns) {
  @media (min-width: $screen-sm-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-pull($columns) {
  @media (min-width: $screen-sm-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width-md) {
  position: relative;
  min-height: 1px;
  padding-left:  ($grid-gutter-width-xs / 2);
  padding-right: ($grid-gutter-width-xs / 2);

  @media (min-width: $screen-sm-min) {
    padding-left:  ($grid-gutter-width-sm / 2);
    padding-right: ($grid-gutter-width-sm / 2);
  }
  @media (min-width: $screen-md-min) {
    padding-left:  ($grid-gutter-width-md / 2);
    padding-right: ($grid-gutter-width-md / 2);
    float: left;
    width: percentage(($columns / $grid-columns));
  }
  @media (min-width: $screen-lg-min) {
    padding-left:  ($grid-gutter-width-lg / 2);
    padding-right: ($grid-gutter-width-lg / 2);
  }
}
@mixin make-md-column-offset($columns) {
  @media (min-width: $screen-md-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-push($columns) {
  @media (min-width: $screen-md-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-pull($columns) {
  @media (min-width: $screen-md-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width-lg) {
  position: relative;
  min-height: 1px;
  padding-left:  ($grid-gutter-width-xs / 2);
  padding-right: ($grid-gutter-width-xs / 2);

  @media (min-width: $screen-sm-min) {
    padding-left:  ($grid-gutter-width-sm / 2);
    padding-right: ($grid-gutter-width-sm / 2);
  }
  @media (min-width: $screen-md-min) {
    padding-left:  ($grid-gutter-width-md / 2);
    padding-right: ($grid-gutter-width-md / 2);
  }
  @media (min-width: $screen-lg-min) {
    padding-left:  ($grid-gutter-width-lg / 2);
    padding-right: ($grid-gutter-width-lg / 2);
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-offset($columns) {
  @media (min-width: $screen-lg-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-push($columns) {
  @media (min-width: $screen-lg-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-pull($columns) {
  @media (min-width: $screen-lg-min) {
    right: percentage(($columns / $grid-columns));
  }
}
