// VIU site specific changes

// Remove side menu apply button from sites <uuid>
//.root_space_uuid_e34f6811-2ef6-4c57-9cf8-66d41a32abc4, // Adventures in mind
//.root_space_uuid_f47015c5-fd41-47a3-9e64-29d4dd042c37, // Grandkids University
//.root_space_uuid_16beacd4-498a-49c7-b605-c4026500b9e0, // ElderCollege
//.root_space_uuid_699c6165-573f-49df-a51f-19c396ebe8f8, // Employees
//.root_space_uuid_743dd7af-297c-4722-8911-8436c02d6df6, // Employee relations
//.root_space_uuid_76c33cab-54fe-48a0-8161-cdb8b47cfda0, // CSI
//.root_space_uuid_12b250ab-0615-4bc2-8eb5-1c9d08b297d2, // CSI v2
//.root_space_uuid_d4c631e8-a24a-45e5-92eb-15c9a50d21d7, // Deepbay
//.root_space_uuid_4160105a-ca2d-42cb-aa84-5c2ac2738ab3, // Milner
//.root_space_uuid_566d7ba1-65da-49e5-873f-e1b2030b9301,  // Residences
//.root_space_uuid_cd30d26a-8484-467e-b736-3ebe57e91da5  // NREP
//{
//  .side-apply-tab {
//    display: none;
//  }
//}

// Remove above for now as it has been requested to remove this tab from
// all sites EXCEPT the programs site on viu.ca. see _site_tabs.scss

// Remove inside main menu 'Start Your Application' button from sites <uuid>
.root_space_uuid_e34f6811-2ef6-4c57-9cf8-66d41a32abc4, // Adventures in mind
.root_space_uuid_f47015c5-fd41-47a3-9e64-29d4dd042c37, // Grandkids University
.root_space_uuid_16beacd4-498a-49c7-b605-c4026500b9e0, // ElderCollege
{
  #cta-apply--main-menu-header {
    display: none;
  }
}

// Remove search tab Milner
.root_space_uuid_4160105a-ca2d-42cb-aa84-5c2ac2738ab3  // Milner
{
  .side-search-tab {
    display: none;
  }
}
