// Colored frame around page
// --------------------------------------------------

body.not-logged-in{
  padding:$frame-width-xs;
  @include breakpoint("sm"){
    padding:$frame-width-sm;
  }
  @include breakpoint("md"){
    padding:$frame-width-md;
  }
}


body.not-logged-in:before{
  z-index: 100;
  content: '';
  user-select: none;
  pointer-events: none;
  position: fixed;
  // viewport height replaces bottom:0 because of the address bar bugs - [todo] find better solution.
  // known issue: https://stackoverflow.com/questions/24944925/background-image-jumps-when-address-bar-hides-ios-android-mobile-chrome/25071131#25071131
  height:100vh;
  left: 0;
  right: 0;
  top:0;
  border: solid $frame-width-xs + 1px $brand-primary;
  border-color: $brand-primary;
  transition: border-color 0.5s ease;

  @include breakpoint("sm"){
    border-width:$frame-width-sm + 1px;
  }
  @include breakpoint("md"){
    border-width:$frame-width-md + 1px;
  }
}

body.not-logged-in .container-fluid > .row:not(#footer):not(.header-hero-container){
  @include breakpoint("xs"){
    // additional padding here on mobile
    padding-left: 10px;
    padding-right: 10px;
  }
}

body.not-logged-in{
  &.darkblue:before{
    border-color:$brand-primary;
  }
  &.white:before{
    border-color:white;
  }
  // adjust top nav text to be visible on white background.
  &.white .viu-audience-nav__list li a{
    color: $brand-secondary;
  }
}
