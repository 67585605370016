// Styles for VIU cta callout box.
// --------------------------------------------------

.viu-callout-box{
  padding: 2em 1.5em;
  margin: 2em 0 3em 0;

  h3{
    margin-top: 0px;
  }
  .cta-button{
    margin-bottom: .5em;
  }
}

.field-callout-image{
  margin-top: 1.5em;
}
