
#viuNavigation{
  font-size:1.5em;

  @include breakpoint("sm"){
    // font-size:1.25em;
  }
  @include breakpoint("md"){
    //font-size:1.5em;
  }
}

.viuNavigation__inner{
  position: relative;
  height:100%;
  overflow-y:auto;
  overflow-x:hidden;
  padding-top:80px;
  @include breakpoint("md"){
    padding-right:30px;
  }
}
@include breakpoint("xs"){
  .viuNavigation__toggle-button__text{
    display:none;
  }
}


#viuNavigation__toggle-button{
  position: absolute;
  font-size:22px;
  top:20px + $frame-width-xs;
  background: $brand-primary;
  padding:.75em 0 .75em 1em;
  text-decoration: none;
  color:white;
  white-space:nowrap;
  right:100%;
  padding-right: $frame-width-xs;
  width: 60px;
  transition: right 0.1s;
  &:hover{
    right: calc(100% + 5px);
  }
  @include breakpoint("sm"){
    padding-right: $frame-width-sm;
    top:60px + $frame-width-sm;
    width: 160px;
  }
  @include breakpoint("md"){
    padding-right: $frame-width-md;
    top:75px + $frame-width-md;
  }
}
.viuNavigation__toggle-button__icon{
  display: inline-block;
  height: 15px;
  width: 20px;
  margin-right: .25em;
  position: relative;
}

.viuNavigation__toggle-button__icon span,
.viuNavigation__toggle-button__icon span:before,
.viuNavigation__toggle-button__icon span:after{
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 20px;
  background: white;
  position: absolute;
  display: inline-block;
  content: '';
  top:0px;
}
.viuNavigation__toggle-button__icon span:before{
  top: 6px;
}
.viuNavigation__toggle-button__icon span:after{
  top: 12px;
}

.viuNavigation--open #viuNavigation__toggle-button{
  padding-right:0;
  &:hover{
    right:100%;
  }
}


#viuNavigation__close-btn{
  position: absolute;
  top:30px;
  right:30px;
  color:white;
  vertical-align: middle;
  em{
    font-style: normal;
  }
  @include breakpoint("sm"){
    display: none;
  }
}

.viuNavigation__utilities{
  @include nav_contained;
}

.viuNavigation__audience_nav{
  margin-left:0;
  margin-bottom:2em;
  columns:2;
  font-size:13px;
  list-style-type: none;
  li{
    margin-bottom:.5em;
  }
}

.cta-button.viuNavigation__apply_btn{
  background:$brand-secondary;
  color:white;
  display: block;
  text-align: center;
  font-size:.75em;
  margin:1em 0;
}

.viuNavigation__audience_nav{
  a{
    color:white;
    &:hover{
      color:white;
    }
  }
}

// Main menu toggle links

.viuNavigation__menu-header{
  display: block;
  color:white;
  font-size: 1em;
  @include breakpoint("md"){
    font-size:1.25em;
  }
  font-weight:bold;
  position: relative;
  padding:10px 50px 10px 0;
  @include nav_contained;
  &:hover, &:focus{
    text-decoration: none;
    color:white;
  }
}

.viuNavigation__toggle-menu{
  @extend .viuNavigation__menu-header;
  &:after{
    content:'\f056';
    font-family: FontAwesome;
    position: absolute;
    right:0px;
    width:50px;
    text-align:right;
    padding-right: .25em;
    color: $brand-secondary-highlight;

  }
  &.collapsed:after{
    content:'\f055';
    color: white;
  }
}

.viuNavigation__social{
  a{
    display:inline-block !important;
    padding:.45em .45em .45em 0 !important;
    &:hover{
      color:$brand-primary;
    }
  }
  .viu-social__socialIcon{
    font-size:1.25em;
  }
  .icon-fallback{
    display: none;
  }
}


/* Bounce for attention */
@include breakpoint("md") {
  .viuNavigation__toggle-button--bounce {
    animation-name: bounce;
    animation-timing-function: ease;
    animation-duration: 4s;
    animation-iteration-count: 3;
    transform-origin: bottom;
  }
  @keyframes bounce {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-20px);
    }
    30% {
      transform: translateX(0);
    }
    32% {
      transform: translateX(-2px);
    }
    38% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
}
