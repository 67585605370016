#sa11y-readability {
  padding: 10px 0px;
  border-bottom: 1px solid #cdcdcd !important;
}

ul#readability-list {
  padding: 0px;
  list-style: none;
  font-size: 15px;
  margin: 5px 0px 0px 0px;
}

#readability-status {
  display: inline-block;
}

.flesch-score {
  margin: 0 7px 0 7px;
}

.readability-label {
  background-color: #e9e9e9 !important;
  border-radius: 0.25em !important;
  padding: 3px 5px !important;
  color: #4b4b4b !important;
  font-size: 15px;
}
