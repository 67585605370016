// VIU Audience Nav

.viu-audience-nav{
  display:none;
  @include breakpoint('lg') {
    display: block;
    position: fixed;
    width:100%;
    top:0;
    left:0;
    z-index:101;
  }

}

@include breakpoint('lg') {
  .viuNavigation__audience_nav{
    display: none;
  }
}

.logged-in .viu-audience-nav{
  background: $brand-secondary;
}

.viu-audience-nav__list{
  margin:0 auto;
  text-align: center;
  line-height: 1.25em;
  li{
    display:inline-block;
    a{
      padding:.2em .5em;
      font-size: .8em;
      color:white;
      &:hover{
        text-decoration:none;
        background:rgba(255,255,255,.2);
      }
    }
  }
}

// temp
.viu-audience-nav form{
  display: none;
}

#audience-search{
  background: #007DBA;
}
