// Styles for Menus and Navs
// --------------------------------------------------

// Make menus scrollable
// NOTE: THIS BREAKS SUBMENUS!!
// Maxheight computed in JS, but set a reasonable value here also
.oa_toolbar .dropdown-menu {
  height: auto;
  max-height: 300px;
  overflow-x: hidden;
}
// Allow specific menus to use sub-menus instead of scrolling
.oa_toolbar .dropdown-menu.oa-noscroll {
  overflow-x: visible;
  max-height: none;
}

.oa_toolbar .dropdown > a > i {
  // Allow click-through on toolbar icons
  pointer-events: none;
}

.oa-navbar .dropdown .dropdown-menu {
  .oa-column {
    border-right: 1px solid #ECECF0;
  }
  .oa-column:last-child {
    border-right: none;
  }
}

.navbar .oa-navbar {
  // Use box-shadow for bottom border so it just darkens whatever color toolbar is.
  box-shadow: inset 0px -1px 0 0 rgba(0,0,0,0.2);
  padding-top: 10px;
  padding-bottom: 10px;
  .pane-oa-toolbar-oa-breadcrumb ul.oa_toolbar li.btn-group > .btn,
  .pane-oa-space-nav ul.oa_toolbar li.btn-group > .btn {
    height: auto;
    padding-bottom: 4px;
  }
  .panel-panel {
    margin: 0;
  }
}
@include breakpoint('xs') {
  .navbar .oa-navbar {
    padding: 0;
  }
}

.pane-oa-space-menu {
  border-bottom: 1px solid #E7E7E7;
}

.pane-oa-edit-minipanel .pull-right .dropdown-menu {
  left: auto;
  right: 0;
}

.dropup ul.dropdown-menu {
  margin-bottom: 0;
}

.pane-oa-toolbar-search ul.dropdown-menu {
  margin-top: -2px;
}

ul.dropdown-menu .item-list {
  h4 {
    text-shadow: none;
    background: none;
    padding: 3px 20px;
    line-height: 20px;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
  }
}

#oa-user-badge .dropdown-menu ul.oa-user-links {
  border-top: 1px solid #D1D1D4;
  margin-top: 0.5em;
  padding-top: 0.5em;
}

@include breakpoint('xs') {
  .oa-navigation {
    width: 100%;
    nav {
      width: 100%;
    }
    ul.menu {
      background: #555;
      a {
        > .caret {
          float: right;
          margin: 6px 12px;
          height: 12px;
          cursor: pointer;
        }
      }
    }
  }
}

// Handle active selector in space-nav menu
.oa-navbar ul.oa-space-nav ul.dropdown-menu ul {
  li a.active {
    position: relative;
    &:not(:hover) {
      background: transparent;
      color: #333;
    }
    &:before {
      content: "\f04b";
      font-family: FontAwesome;
      position: absolute;
      z-index: 1;
      left: 8px;
      font-style: normal;
      font-size: 0.9em;
      top: 0;
      padding: 3px 0;
    }
  }
  li.oa-sections a.active {
    &:before {
      content: " ";
    }
  }
}
