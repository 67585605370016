//Vars

$left-padding: 50px;

// Overwrite a critical CSS rule if applied:
.mm-listview > li {
  height: auto;
}

.mm-menu, .mm-panel{
  background: $brand-primary;
  color:white;
}

.mm-panels{
  background: $brand-primary;
}

.mm-panels>.mm-panel{
  overflow-y:hidden;
}

vertical>.mm-panel, .mm-menu .mm-listview>li.mm-opened.mm-vertical>a.mm-next, .mm-menu.mm-vertical .mm-listview>li.mm-opened>.mm-panel, .mm-menu.mm-vertical .mm-listview>li.mm-opened>a.mm-next {
  background: $brand-primary;
}
.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
   padding: 10px 0 10px 0px;
}

.mm-listview, .mm-navbar .mm-navbar__title{
  font-size: .75em;
  line-height:1.1em;
  font-weight:bold;
}

.mm-listview>li{
  @include nav_contained;
}

.mm-listview>li>a, .mm-listview>li>span{
  padding:.7em .7em .7em 0;
  white-space: normal;
}

.mm-listview>li:not(.mm-divider):after{
  width:300%;
  left:-100%;
  border-color: $brand-secondary-highlight;
}

.mm-panel:not(.mm-hasnavbar) .mm-listview>li:not(.mm-divider):first-child:before{
  content: '';
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: $brand-secondary-highlight;
  display: block;
  position: absolute;
  left: -100%;
  width: 300%;
  right: 0;
  top: 0;
}
.mm-next, .mm-btn.mm-prev{
  text-align: center;
  padding:0;
  height:2.4em;
}

.mm-btn.mm-prev{
  padding:0;
  width:30px;

  font-size:.75em;
  @include breakpoint("sm"){
    width:60px;
  }
}

.mm-btn_next:after{
  border-color:white;
}
.mm-btn_next:hover:after{
  border-color:$brand-secondary-highlight;
}

.mm-listitem__btn{
  width:50px;
}
.mm-btn_next:after{
  right:15px;
}

/*
.mm-next:hover{
  color: $brand-primary;
}
.mm-listview .mm-next:before{
  border:0;
}

.mm-next:after,.mm-prev:before{
  border:0;
  display: inline-block;
  height:auto;
  width:auto;
  bottom:auto;
  right:.5em;
  left:auto;
  font-size:1em;
  font-family:FontAwesome;
  position: absolute;
  top: 50%;
  transform:unset;
  transform: translateY(-50%);
}

.mm-next:after{
  content:"\f061";
}
.mm-prev:before{
  color:white;
  content:"\f060";
  font-family:FontAwesome;
}
*/

.mm-panels > .mm-panel:first-child{
  .mm-navbar{
    display: none;
  }
}
.mm-navbar{
  background: $brand-secondary;
  border:0;
  padding:0;
}
.mm-navbar>* {
  padding: .7em 0;
}
.mm-navbar .mm-navbar__title{
  text-align: left;
  margin:0 auto;
  width:70%;
  position: relative;
  max-width: 400px;
  overflow:visible;
  @include breakpoint("sm"){
    max-width: 520px;

    &:before{
      content:"";
      width:5px;
      background:$brand-primary;
      display:block;
      position: absolute;
      top:-50%;
      height:200%;
      left:-15px;
    }
  }
  >span{
    width:100%;
  }

}
.mm-menu .mm-navbar a, .mm-menu .mm-navbar>*{
  color:$brand-primary;
}

.viuNavigation__social .mm-listitem__text{
  flex:none;
}

.mm-panels>.mm-panel.mm-hasnavbar {
  padding-top: 1.8em;
}
.mm-navbar, .mm-btn{
  //height:1.8em;
}
.mm-navbar__btn:before{
  border-color: white;
}
.mm-listitem__btn:not(.mm-listitem__text) {
  border-left: 1px solid $brand-secondary;
}
// Active trail highlight

.expanded.active-trail .mm-next{
  color:$brand-primary;
}

.mm-menu.mm-hoverselected .mm-listview>li>a.mm-fullsubopen+a, .mm-menu.mm-hoverselected .mm-listview>li>a.mm-fullsubopen+span, .mm-menu.mm-hoverselected .mm-listview>li>a:not(.mm-fullsubopen), .mm-menu.mm-parentselected .mm-listview>li>a.mm-fullsubopen+a, .mm-menu.mm-parentselected .mm-listview>li>a.mm-fullsubopen+span, .mm-menu.mm-parentselected .mm-listview>li>a:not(.mm-fullsubopen) {
  transition: color .2s ease;
}

.mm-menu .mm-listview>li.mm-selected>a:not(.mm-next), .mm-menu .mm-listview>li.mm-selected>span {
  background:transparent;
  color:$brand-primary;
}
.mm-menu.mm-hoverselected .mm-listview>li>a.mm-fullsubopen:hover+span, .mm-menu.mm-hoverselected .mm-listview>li>a:not(.mm-fullsubopen):hover {
  background:transparent;
  color:$brand-primary;

}

.mm-menu a, .mm-navbar__title{
  transition: padding-left, color !important;
  transition-duration: 250ms,250ms !important;
}
.mm-menu a:hover,
.mm-navbar__title:hover,
.mm-navbar__btn:not(.mm-hidden)+.mm-navbar__title:hover{
  padding-left: 0.5em;
}

.mm-menu a, .mm-menu a:active, .mm-menu a:hover, .mm-menu a:link, .mm-menu a:visited{
  color:white;
}

.mm-listitem_selected>.mm-listitem__text,
.mm-menu_selected-hover .mm-listitem__btn:hover, .mm-menu_selected-hover .mm-listitem__text:hover{
  background:none;
}
