// Styles for VIU numbered steps
// --------------------------------------------------

.viu-step{
  margin: 3em 0;
}

@include breakpoint("sm"){
  .viu-step{
    padding-left:5em;
    position: relative;
  }

  .viu-step__number{
    font-family: 'ProximaNova-LightIt', sans-serif;
    color:#C9C8C8;
    position: absolute;
    top:0; left:0;
    line-height: 1em;
    font-size: 84px;
    font-weight: normal;
  }
}

@include breakpoint("md"){
  .viu-step__number{
    font-size: 120px;
  }
  .viu-step__heading{
    font-size:38px;
  }
}

