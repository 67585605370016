// Homepage hero video

// Mobile first
.homepageHero__video{
  display: none;
}

// Video background uses CSS background rather than picture to allow it not to load if video is showing.
.page-section--homepage-video .page-section__bg{
  background-size:cover;
}

@include breakpoint("sm"){
  .homepageHero__video{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}
/* 1. No object-fit support: */
@media (min-width:768px) and (min-aspect-ratio: 16/9) {
  .homepageHero__video { height: 300%; top: -100%; }
}
@media (min-width:768px) and (max-aspect-ratio: 16/9) {
  .homepageHero__video { width: 300%; left: -100%; }
}
/* 2. If supporting object-fit, overriding (1): */
@supports (object-fit: cover) {
  .homepageHero__video {
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
  }
}