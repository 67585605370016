// Stock OA Theme Page Layout
// -----------------------------------------------------------------------------

html{
  overflow-x:hidden;
}
body{
  overflow-x:auto;
}

.page-admin .pane-page-content {
  padding: 20px 20px;
  border-right: 1px solid #E4E3E6;
  border-bottom: 1px solid #E4E3E6;
}

.breadcrumb {
  margin-bottom: 10px;
}

#main-wrapper #main {
  //margin: 0 0 30px 0;
  &:focus{
    outline: none;
  }
}

@include breakpoint('md') {
  #main-wrapper.oa-layout-fluid {
    max-width: 95%;
    #main {
      //width: auto;
    }
  }
}

body.oa-home .pane-page-content .radix-layouts-content > .panel-panel-inner {
  background-color: transparent;
}

body #footer {
  margin: 0 (- $grid-gutter-width/2);
  font-size: 1em; // [viu mod]
  line-height: 1.5;
  background-color: #003853;
  border-top: 0;
  overflow: visible;
}

body.oa-no-page-title #main-wrapper #main {
  padding: 40px 0;
}

.panel-display .oa-responsive-desktop.oa-responsive-region-left {
  .btn {
    color: white;
    &:hover {
      color: #5bbd5b;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    &.btn-circle {
      width: 36px;
      height: 36px;
      margin-left: 2px;
      padding: 7px;
    }
  }
}

// move tray's parent off edge of screen to avoid flicker
.oa-layout-sidebar1 {
  width: 42px;
  left: -50;
}

.navbar-tray {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.59);
}

.navbar .oa-responsive-regions-toggle-left {
  position: fixed;
  padding: 0 10px;
  left: 0;
  top: 16px;
  &.btn {
    color: #DDD;
    background: transparent;
    border-color: transparent;
    z-index: 1001;
    &.btn-circle {
      width: 36px;
      height: 36px;
      padding: 3px;
      margin-left: 2px;
    }
  }
  .fa {
    font-size: 2em;
    margin-right: 0;
  }
}
@include breakpoint('xs') {
  .navbar .oa-responsive-regions-toggle-left {
    top: 8px;
  }
}

body.oa-responsive-region-left-on {
  .navbar .oa-navbar {
    .oa-responsive-regions-toggle-left {
      &.btn.btn-circle:hover,
      &.btn.btn-circle:active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        background: transparent !important;
        border-color: gray;
      }
    }
  }
}

#toolbar-menu-button.btn-circle {
  padding: 7px 10px;
}


/** VIU Layout **/

body{
  position: relative;
}

#main-wrapper{
  background:transparent !important;
}

// No header style class or these two, set large headers
body:not(.fullscreen-hero) .row.oa-flex-grow{
  //z-index:4; // REMOVED THIS TO FIX https://gitlab.viu.ca/web/viu_theme/issues/6
}

body:not(.fullscreen-hero) .row.oa-flex-grow,
.header-style-all_large:not(.fullscreen-hero) .row.oa-flex-grow,
.header-style-home_large:not(.fullscreen-hero) .row.oa-flex-grow
{
  @include viewport-unit(margin-top, -45vh);
}

// For this header-style, override regular pages to small headers.
.node-type-viu-content-page.header-style-home_large:not(.fullscreen-hero){
  .row.oa-flex-grow
  {
    @include viewport-unit(margin-top, -85vh);
  }
  &.no_notch {
    .page-section--notch-height, .page-section--notch-height .page-section__content{
      min-height: 35vh;
    }
  }
}

// For this header-style, override all pages to small headers.
.header-style-all_small:not(.fullscreen-hero){
  .row.oa-flex-grow
  {
    @include viewport-unit(margin-top, -85vh);
  }
  &.no_notch {
    .page-section--notch-height, .page-section--notch-height .page-section__content{
      min-height: 35vh;
    }
  }
}

// Removal of notch option.
body.no_notch .row.oa-flex-grow
{
  margin-top: 0 !important;
  .main {
    padding-top: 0 !important;
  }
}

// white backdrop behind content
.content-canvas{
  @include make-xs-column(12);
  @include make-sm-column(11);
  @include make-sm-column-offset(1);
  @include make-md-column(10);
  @include make-md-column-offset(1);

  .content-canvas-inner{
    background:white;
    padding-top:2em;
    border-top:5px solid $brand-primary;
  }
}

#site-context-heading{
  text-align:left;
  font-size: 20px;
  display:inline-block;
  background: $brand-primary;
  color: white;
  padding: .5em 1em;
  margin:0;
  a{
    color:white;
    font-weight: bold;
  }
}

// Do not show context for fullscreen hero pages.
.fullscreen-hero{
  #site-context-heading{
    display:none;
  }
  .content-canvas .content-canvas-inner{
    border:0;
  }
}
.no_notch{
  #site-context-heading{
    display:none;
  }
  @include breakpoint('md') {
    .content-canvas {
      position: relative;
    }
    #site-context-heading {
      display: block;
      position: absolute;
      bottom: 100%;
      left: -10px;
    }
  }
  .content-canvas .content-canvas-inner{
    border:0;
  }
}

/** Actual content container within the canvas
    Because this is nested within the canvas above,
    and design specs require it to conform to the
    outer 12 col grid, we must redefine the grid-columns
    for the nested grids, depending on the breakpoint of
    its container above.
**/

.content-container, .no_notch .site-context-container{
  @include make-xs-column(11);
  @include make-xs-column-offset(1);
}

// Set cols for nested grid calcs
$grid-columns: 11;
.content-container, .no_notch .site-context-container{
  @include make-sm-column(9);
  @include make-sm-column-offset(1);
}
// Reset our grid back to what its supposed to be
$grid-columns: 12;

$grid-columns: 10;
.content-container, .no_notch .site-context-container{
  @include make-md-column(8);
  @include make-md-column-offset(1);
}
$grid-columns: 12;

.page-admin .content-container, .page-admin.no_notch .site-context-container{
  @include make-xs-column(12);
  @include make-xs-column-offset(0);
}

/* Adjustments for admin interfaces */
.page-admin,
.page-node-edit {
  .container {
    width: auto;
  }
  #main-wrapper{
    margin-top: 0px;
  }
  .viu-audience-nav{
    position:absolute;
  }
}

// Fixes a z-index issue with footer, add some padding below content
.content .panel-pane.pane-page-content{
  z-index: 2;
  padding-bottom: 1em;
}


// Drupal Coffee module override to fix layout issue it causes on mobile
#coffee-form.hide-form{
  display: none;
}

// Body class to remove padding from above and below content sections when all sections are used.
.no-content-padding{

  // Remove content area padding because its all section blocks
  & #main-wrapper #main{
    padding-top:0 !important;
    padding-bottom:0 !important;
  }
  h1.title{
    display: none;
  }
  .content-canvas .content-canvas-inner{
    padding-top:0;
  }
  .content .panel-pane.pane-page-content{
    padding-bottom:0;
  }
}
