// Styles for Main Program Search
// --------------------------------------------------
// Hide the apply button
#edit-submit-program-area-course-live-results {
  display:none;
}

#autocomplete {
  z-index: 105;
  border: none;
  background: #fff;
  box-shadow: 0px 0px 5px #888888;
  border-radius: 20px;
  transform: translateY(10px);
  padding: 20px 0;
  .live-result-search-item-wrapper {
    text-align: left;
    padding: 10px 20px;
    white-space: normal;
    &.selected{
      background: $brand-secondary;
      a, .viuSearchLR__Title, .viuSearchLR__subTitle{
        color:white;
        text-decoration: none;
      }
    }
    .viuSearchLR__Title{
      font-size: 1em;
      margin-bottom: 0;
      margin-top: 0;
      color: $brand-secondary;
    }
    .viuSearchLR__subTitle{
      font-size:.8em;
      color: $brand-secondary;
    }
    .viuSearchLR__advanced-link{
      color: $brand-secondary;
    }
  }
}

.views-exposed-widget.views-widget-filter-search_api_views_fulltext{
  float:none;
}

.input-group .form-control.live-results-search-textfield:not(:first-child):not(:last-child){
  border-radius:500px;
}


.form-autocomplete.live-results-search-textfield{

  color:$brand-primary;
  border-radius: 500px;
}

html.js .form-autocomplete.live-results-search-textfield{
  // Magnifying glass
  background-image:url('/profiles/viu/themes/viu_theme/assets/images/icon/search-icon.svg');
  background-size:20px;
  background-position:right 1em top 50% !important;
  &::-ms-clear {
    display: none;
  }
}

html.js .live-results-search-textfield.throbbing{
  background-image:url('/profiles/viu/themes/viu_theme/assets/images/icon/AjaxLoader.gif');
}