// Styles for fields.
// --------------------------------------------------

.views-field-field-oa-worktracker-priority,
.views-field-comment-count,
.views-field-field-oa-worktracker-task-type,
.views-field-field-oa-worktracker-task-status {
  text-align: center;
}

