// Styles for buttons.
// --------------------------------------------------

.btn-default{
  @include cta-button;
  font-size:1em;
  box-shadow:none;
}

// CTA styles

.cta-button{
  @include cta-button;
}

.cta-button--centered {
  margin-left: auto;
  margin-right:auto;
}