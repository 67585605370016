// VIU Search hero with title


// Header "Vancouver Island University"

.viu_title{
  color:white;
  text-transform: uppercase;
  text-align: center;
  font-size:1em;
  position: absolute;
  top:20px;
  margin-top:0;
  left:50%; margin-left: -50px;
  width:100px;
  font-weight: normal;
  letter-spacing: 0.2em;
  line-height:1.2em;

  @include breakpoint("sm"){
    font-size:1.25em;
    top: 60px;
    width:auto;
    left:0; right:0;
    margin-left:0;
  }
  @include breakpoint("md"){
    font-size:1.5em;
    top: 75px;
  }
  strong{
    display: block;
    letter-spacing: 0.05em;
  }
  // Fix for wide but short display situations.
  @media screen and (min-width: 768px) and (max-height: 600px) {
    position:static;
  }
}

.homepageHero__slogan{
  text-transform:none;
  font-size:25px;
  margin-bottom:.4em;

  @include breakpoint("sm"){
    font-size:40px;
  }
  @include breakpoint("md"){
    font-size:50px;
  }
}


// Homepage hero CTAs

.homepageHero__cta{
  width:100%;
}

.page-section--search-hero .page-section__content{
  padding: 80px 0;
}


// Search
.page-section.page-section--search-hero{
  // Search needs to go over the body
  z-index: 3;
}

.homepageHero__search{
  margin-bottom:.5em;
  @include breakpoint("sm") {
    max-width:400px;
    margin-left: auto;
    margin-right:auto;
  }
}

// Scroll down

.homepageHero__scroll{
  //position: absolute;
  //left:0; right:0;
  //bottom:120px;
  text-align: center;
  font-size: 25px;
  margin-top: 3em;
  i{
    display: block;
    margin-top:.5em;
  }
  a:hover, a:focus{
    text-decoration: none;
  }
  @include breakpoint("sm"){
    //bottom:200px;
  }
}
