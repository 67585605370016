// Menu
// Styles for Menus and Navs
// --------------------------------------------------


// Styles for VIU accordion style vertical nav.  Side nav and Main nav @ mobile sizing
//------------------------------------------------------------------------------------

.nav-viu {
  padding-left: 0;
  margin-left:0;
  margin-bottom:0;
  font-size: .8em;
  li {
    padding: 0;
    background-color: transparent;
    border: 1px solid rgba(0,0,0,.1);
    &:not(:last-child){
      border-bottom:0;
    }
    .nolink{
      color: #555;
      display: block;
      padding: 7px 10px;
    }
    &, &.active, &.active:hover, &.active:focus{
      border-color:rgba(0,0,0,.1);
    }
    a {
      color: #555;
      display: block;
      padding: 7px 10px;
      transition: all 0.1s ease-in-out 0s;
      &:hover {
        text-decoration: none;
        background: #2D83C5;
        color: white;
      }
    }
    &.expanded{
      > a, > a.active, >.nolink{
        padding-right:40px;
      }
    }
    ul {
      padding: 0;
      margin-left:0;
      list-style: none;
    }
    &.active{
      &, &:hover{
        background-color: transparent;
      }
    }
    li{
      border-bottom:0;
      border-right:0;
      &, &.active, &.active:hover{
        border-top:1px solid rgba(0,0,0,.1);
      }
    }
    a.active { //active, second level deep (first is dark background, light text)
      color: white;
      background: #2D83C5; //default color scheme
      &:hover{
        color: white;
      }
    }
    i.fa { // menu item icons
      margin-left: 5px;
      margin-right: 5px;
      font-size: 85%;
    }
  }
  > li.active-trail{  //TOP level links
    > a{
      //border: 1px solid #ddd;  //border around top level links
    }
    > ul > .first{
      &, &:hover{
        //border-top:0;
      }
    }
    &.active{
      >a, >.nolink{
        position: relative;
        color: #FFF; //default color scheme
        &:after{
          content:"";
          display: block;
          background:#003F5F; //default color scheme
          width: 100%;
          height:2px;
          position: absolute;
          bottom:-1px;
          z-index: 3;
          left:0;
        }
      }
      >a:hover, a:after{
        color:white;
      }
    }
  }
  .collapse, .collapsing{  //compounding color shading on nested menus + bit of indenting
    background: rgba(0,0,0,.04);
    margin-left:8px;
  }
}


//Dropdown controller handles on the right

.list-toggle.menu-block-handle{
  border:0;
  position: absolute;
  background: transparent;
  top:0;
  right:0;
  padding: 7px 10px;
  span{ //for accessibility tags
    display:inline-block;
    background: transparent;
    text-indent: -9999em;
  }
  &:after{
    color:#777;
    font-size: 13px;
    height:20px;
    width:20px;
    line-height: 19px;
    @include border-radius(50%);
    border: 1px solid transparent;
    top:50%;
    margin-top:-10px;
  }
  &.active{
    &:after{
      color:#777;
      line-height: 18px;
    }
  }
  &:hover:after{
    border: 0;
    background:#2D83C5;
    color:#fff;
  }
  //active trail changes to handle
  a.active + &:after, a:hover + &:after{
    color:#fff;
  }
}
// If its a nolink item, make the whole thing click to expand
.nolink{
  &+ .menu-block-handle {
    width: 100%;
  }
}


// Main Nav overrides for horizontal display at larger device sizes
// -----------------------------------------------------------------
@media (min-width: $tabletMin) {
  .main-nav-viu {
    display: table; //fallback
    display: flex;
    > li {
      position: relative;
      float: none;
      display: table-cell; //fallback
      display: flex;
      @include align-items(stretch);
      border-top:0;
      border-bottom:0;
      border-left:0;
      z-index: 3;
      //top level hover, only on desktop/horizontal
      &:hover, &.active-trail {
        &:before{
          content:"";
          display:block;
          position:absolute;
          width:100%;
          height:2px;
          background: #003F5F;
        }
      }
      > .nolink, > a, > a.active{
        font-size: 12px;
        padding: 10px 20px;
      }
      > a, > a.active {
        &:hover{
          background:#fff;
          color: #555;
          + .menu-block-handle:after{
            color:#777;
          }
        }
      }
      > .list-toggle{
        padding-top:9px;
        padding-bottom:9px;
        &:after{
          content: "\f107";
        }
      }
      > .subnav{
        position: absolute;
        margin:0;
        min-width:250px;
        background: #fff;
        left:0;
        top:100%;
        z-index: 9;
        @include box-shadow(0 6px 12px rgba(0,0,0,0.175));
      }
      &.edge > .subnav{
        left:auto;
        right:0;
      }
    }

    // Is a non touch device? Change to simple hover nav
    .no-touch &{
      .list-toggle{
        display: none;
      }
      .subnav .subnav{
        position: absolute;
        left:100%;
        min-width:250px;
        top:-1px;
        margin-left: 0;
        background: #fff;
        @include box-shadow(0 6px 12px rgba(0,0,0,0.175));
      }
      //subnavs that would otherwise bleed past the edge of the viewport
      .subnav .edge .subnav{
        left:auto;
        right:100%;
        z-index:1;
      }
      .expanded{
        > a, > .nolink{
          padding-right:30px;
          &:before {
            top: 10px;
            right: 15px;
            font-size: 11px;
            content: "\f107";
            position: absolute;
            font-weight: normal;
            display: inline-block;
            font-family: FontAwesome;
          }
        }
        &:hover{
          >.subnav{
            display: block;
            visibility: visible;
            opacity:1;
            transition: 0.1s 0.2s;
            height:auto !important;
          }
        }
      }
      .subnav .expanded{
        > a, > .nolink{
          &:before{
            content: "\f105";
          }
        }
      }
      .collapse{
        display:block;
        visibility: hidden;
        opacity:0;
        transition: 0.1s 0.2s;
      }
    }

    // Don't show dropdowns on main menu if in horiz orientation by default
    .collapse.in {
      display: none;
    }
    .touch &.ready{
      // show dropdowns on main menu if in horiz orientation and touch-enabled and js docready
      .collapse.in {
        display: block;
      }
    }
  }

}


//move the dropdown arrow over just a bit for extra room
.header-viu .dropdown > a::after {
  @media (min-width: $tabletMin) {
    right: 10px;
  }
  @media (min-width: $desktopMin) {
    right: 15px;
  }
}






// Footer styles
// --------------------------------------------------
.region-footer {
  overflow: hidden;
  ul.menu {
    margin-bottom: 0;
    > li {
      float: left;
      min-width: 150px;
      list-style: none !important;
      > a {
        font-weight: bold;
        font-size: 14px;
      }
      > ul {
        > li {
          float: none;
          > a {
            font-weight: normal;
            font-size: 12px;
          }
        }
      }
    }
    a {
      border: 0;
      display: block;
      padding: 0 5px;
      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
}

// Fix for contextual-tabs dropdown hover colour
#main ul.dropdown-menu ul li a:hover {
  @include ctb-gradient-vertical($dropdownLinkBackgroundHover, darken($dropdownLinkBackgroundHover, 5%));
}

// Workaround for '<nolink>' links in menus.  Allows for '#' links without the visual pointer indicator.
.viu a.nolink:hover {
  cursor: default;
}

.toolbar-search .btn {
  background: none;
  color: #ffffff;
}
