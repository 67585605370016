//
// Styles to support legacy aspx photo galleries after migration
// --------------------------------------------------------------

.ss_thumbs{
  ul{
    list-style-type: none !important;
    padding-left:0 !important;
  }
  li{
    display:inline-block;
    width:130px;
    height:130px;
    padding:15px;
    vertical-align: top;
  }
}