// VIU Header hero

.header-hero-container, .page-section.page-section--hero{
  padding:0;
}

.page-section.page-section--hero{
  position: relative;
  left:0;
  width:100%;
  text-align:center;
  z-index:0;
  @include breakpoint("xs"){
    // override additional padding here on mobile because it doesn't go full viewport like others.
    padding-left: 14px;
    padding-right: 14px;
  }
}

.page-section--hero .page-section__content{
  width:100%;
}

.page-section--hero .page-section__content__inner{
  @include make-xs-column(8);
  @include make-xs-column-offset(2);
  @include make-lg-column(4);
  @include make-lg-column-offset(4);
  position: static;
}

.page-section--notch-height{
  border-bottom:5px solid #003B5C;
}

