// Footer
// Styles for VIU footer
// --------------------------------------------------

.viuFooter, body #footer{
  background:white;
  text-align: center;
  color: $brand-primary;
}

.viuFooter-content{
  width:100%;
}

.viuFooter a{
  color:$brand-primary;
}

.viuFooter__linkList__heading{
  font-weight:bold;
  font-size:14px;
  @include breakpoint('sm'){
    font-size:18px;
  }
  @include breakpoint('md'){
    font-size:22px;
  }
}

.viuFooter__logo{
  display: block;
  max-width: 150px;
  margin:0 auto 2em auto;
}

.footer__linkList__list{
  margin-left:0;
  margin-bottom: 2em;
}

.viuFooter__contact__heading{
  display: block;
  margin: 1em 0;
}

.viuFooter__contact__address{
  margin-bottom:1.5em;
  address{
    margin-bottom: 0;
  }
}

@include breakpoint("sm"){
  .viuFooter__contact__address{
    address{
      float:left;
      width:50%
    }
  }
  .viuFooter__contact__phone-email{
    float:left;
    width:50%
  }
}


#footer-google-map{
  max-width:100%;
}

.viuFooter__acknowledgement{
  text-align:center;
  margin-bottom:.5em;
}

.viuFooter__primary{
  margin-bottom:2em;
}
.viuFooter__primary__inner{
  @include make-xs-column(8);
  @include make-xs-column-offset(2);
  @include make-sm-column(10);
  @include make-sm-column-offset(1);
}

.viuFooter__links-secondary{
  text-align: center;
  li{
    display:inline-block;
    &:not(:last-child):after{
      content:" |";
    }
  }
  a{
    font-size: 0.8em;
    font-weight: bold;
  }
}

.viu-copyright {
  text-align: center;
  font-size:12px;
  line-height:$frame-width-sm;
  @include clearfix;
}


@media (min-width: $screen-sm) {

  .viuFooter{
    text-align: left;
  }
  .viuFooter__logo{
    max-width: 200px;
  }

  .viu-copyright{
    position: absolute;
    width:100%;
    bottom:0;
    left:0;
    z-index:101;
    color:white;
    a{
      color:white;
    }
  }
  .viuFooter .page-section__content{
    padding-bottom: 1em; //reduce bottom section padding for footer
  }

}

@media (min-width: $screen-md) {

  .viuFooter{

  }

  .viuFooter__logo{
    display: block;
    max-width: 250px;
    margin:0 auto 4em auto;
  }
  .viu-copyright {
    line-height: $frame-width-md;
  }

}
