// Styles for Tables
// --------------------------------------------------

.table-bordered {
  border: 0 !important;
}

.table-bordered > tbody > tr > td{
  border: none;
  border-bottom: 1px solid #ddd;
}

.table > thead > tr > th,
.sticky-header > thead > tr > th {
  background: transparent;

}

.table > thead > tr > th.active {
  background: #e0e0e0;
}

.table-bordered > thead > tr > th,
.sticky-header > thead > tr > th {
  border: none;
  border-bottom: 1px solid #ddd;

  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
}

table td > div.form-type-checkbox,
table th > div.form-type-checkbox {
  text-align: center;
}



.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: transparent;
}

// Styles for Tabs, Messages, ..etc
// --------------------------------------------------

.pane-content {
  position: relative;
}

.oa-pane .pane-content {
  position: relative;
  padding: 0;
  .oa-list {
    margin: 0;
  }
}

.oa-pane.inner-pane .panel-pane {
  padding-bottom: 1em;
}

/* try to target the main content region when inside a panel layout */
.pane-page-content .radix-layouts-content {
  > .panel-panel-inner {
    //padding: 0 20px 20px 20px;  // [VIU mod]
      padding-right:25px;
    //border-right: 1px solid #E4E3E6; // [VIU mod]
    //border-bottom: 1px solid #E4E3E6; // [VIU mod]
  }
  .oa-pane.inner-pane .panel-pane {
    padding-bottom: 0;
    padding-top: 1.5em;
  }
}
body.panels-ipe .pane-page-content .radix-layouts-content > .panel-panel-inner {
  border-right: none;
  border-bottom: none;
  //padding: 0 20px;
  padding: 0; //[viu mod]
}

@include breakpoint('md') {
  .pane-page-content .radix-layouts-content {
    > .panel-panel-inner {
      padding: 0; //[viu mod]
    }
  }
  body.panels-ipe .pane-page-content .radix-layouts-content > .panel-panel-inner {
    //padding: 0 40px;
    padding: 0; //[viu mod]
  }
}

.oa-list {
  &.well {
    background: transparent;
    border: none;
  }
}

.oa-list-header {
  padding-top: 0;
}

.group-content {
  > li {
    border-top: 1px solid #EAEAEA;
    padding: 10px 0;
  }
}

.radix-layouts-sidebar {
  .group-content li {
    padding: 5px 0;
  }
}

.oa-list-category {
  text-align: right;
  margin-left: 10px;
}

.pane-pane-oa-messages{
  margin-top: 1em;
}