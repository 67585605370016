// Typography
// -----------------------------------------------------------------------------

body {
  font-family: "ProximaNova",Corbel,Arial,sans-serif;
  font-size:14px;
  @include breakpoint("sm"){
    font-size:16px;
  }
  @include breakpoint("md"){
    font-size:18px;
  }
}

p, ol, ul {
  font-family: "ProximaNova",Corbel,Arial,sans-serif;
}

ol, ul {
  margin-bottom: 1.5em;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  font-style: normal;
  line-height:1.2;
  letter-spacing: .02em;
  margin:0 0 .25em 0;
}
.content-container{
  h1, h2, h3, h4, h5, h6{
    margin-top: 1em;
  }
}

.title.page-title{
  margin-bottom: .75em;
}

strong{
  letter-spacing: .02em;
}

.mceContentBody {
  &, p, ol, ul {
    line-height: 1.5em;
    color: #60616d;
    font-size: 16px;
    margin: 0;
    // enforce consistent paragraph and list spacing in the main content area
    margin-bottom: 1em !important;
  }
}

.pane-page-content {
  .field-type-text-with-summary .field-item,
  .field-type-text-long .field-item,
  .paragraphs-item-paragraph-snippet,
  .paragraphs-item-paragraph-content,
  .oa-event-description,
  .oa-news-body,
  .paragraphs-item-viu-text-section{
    p, ol, ul {
      margin: 0;
      // enforce consistent paragraph and list spacing in the main content area
      margin-bottom: 1em !important;
    }
    &, p, ol, ul {
      line-height: 1.5em;
      //color: #60616d; // [viu mod]
      font-size: 18px;
    }
    ol, ul {
      padding-left: 25px;
    }
  }
  .paragraphs-item-paragraph-content {
    font-size: 14px;
  }
  .entity-paragraphs-item {
    margin-bottom: 1em;
  }
  .panel-panel-inner > .panel-pane.pane-node-field-oa-related {
    padding-top: 0;
  }
  .pane-node-comments .oa-list .oa-comment-reply-body,
  .pane-content #comments .oa-list .oa-comment-reply-body,
  .oa-comment-reply-body {
    line-height: 1.5em;
    color: #60616d;
    font-size: 15px;
    margin: 0 0 10px 0;
    p {
      font-size: 15px;
      margin: 0 0 1em 0;
    }
    .user-info, .links {
      font-size: 13px;
    }
  }
}


h1 {
  color: #2a2c37;
  line-height: 1.1em;
  font-size: 24px;
  @include breakpoint('sm'){
    font-size:42px;
  }
  @include breakpoint('md'){
    font-size:50px;
  }
  &.title{
    color:$brand-primary;
    margin-top: 0;
  }
}

h2 {
  font-weight: bold;
  background-image: none;
  font-size: 20px;
  @include breakpoint('sm'){
    font-size:30px;
  }
  @include breakpoint('md'){
    font-size:36px;
  }
}
.oa-pane .pane-title {
  font-size: 20px;
  margin: 0 0 0.8em 0;
  padding: 0;
  letter-spacing: .04em;
  font-weight: bold;
  background-image: none;
}
/*
.radix-layouts-sidebar {
  opacity: 0.85;
  h2, h3 {
    font-size: 16px;
    padding: 0;
    letter-spacing: .04em;
    font-weight: bold;
    background-image: none;
  }
  h3 {
    font-size: 14px;
    margin: 5px 0;
  }
  .pane-title {
    font-size: 14px;
    margin: 0 -15px 0;
    padding: 10px 15px 5px;
    a{
      text-decoration: none;
    }
  }
  .field-type-text-with-summary .field-item p {
    font-size: 14px;
  }
  .group-content li {
    font-size: 14px;
  }
}
*/
h3, h3.panel-title, h3.pane-title {
  font-weight: 800;
  font-size: 14px;
  @include breakpoint('sm'){
    font-size:20px;
  }
  @include breakpoint('md'){
    font-size:26px;
  }
}

.view-oa-team-list h3 a {
  color: #777;
  text-transform: uppercase;
  font-weight: normal;
}

h4 {
  letter-spacing: .04em;
  font-size: 1em;
  @include breakpoint('sm'){
    font-size:18px;
  }
  @include breakpoint('md'){
    font-size:22px;
  }
}

.entity-paragraphs-item .field-label-heading .field-label {
  font-size: 14px;
}
/*
.radix-layouts-sidebar {
  color: #60616d;
  font-size: 15px;
}
*/
.group-header {
  margin-top: 0;
  h3, h4 {
    font-size: 12px;
    line-height: 1.1;
    color: #6D7074;
    font-weight: bold;
    font-style: normal;
    border-top: 4px solid #EAEAEA;
    margin-bottom: 0;
    padding-top: 1.3em;
    padding-bottom: 1.3em;
  }
}

.view-content table > caption {
  font-size: 12px;
  line-height: 1.1;
  color: #6D7074;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 1.3em;
}

.oa-responsive-region .fa {
  margin-right: 0;
}

.more-link {
  font-style: italic;
  font-size: 15px;
}

@media screen and (max-width: 1024px) {
  abbr[title] {
    position: relative;

    /* ensure consistent styling across browsers */
    text-decoration: underline dotted;
  }

  abbr[title]:hover::after,
  abbr[title]:focus::after {
    content: attr(title);

    /* position tooltip like the native one */
    position: absolute;
    left: 0;
    bottom: -30px;
    width: auto;
    white-space: nowrap;
    background-color: #1e1e1e;
    color: #fff;
    border-radius: 3px;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
    font-size: 14px;
    padding: 3px 5px;
  }
}
