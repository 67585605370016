
.row--brightedge {
  background-color: $brand-primary;
}

.be-ix-link-block {
   background-color:transparent !important;
   margin-left: 0px !important;
   padding: 30px 0 !important;
   width:100%;
}