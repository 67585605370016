// This file is for fixes to bootstrap styles, in panels particularly

.panel.panel-bootstrap-pane{
  border-width: 1px;
  border-style: solid;
  .panel-title{
    font-size: 1em;
    margin-top: 0;
    a{
      text-decoration: none;
    }
  }
}

.panel-bootstrap-pane.service-block{
  &.rounded{
    border-radius:5px;
  }
  &.rounded-2x{
    border-radius:10px;
  }
  .service-block-title{
    margin-top: 0;
  }
}

.service-block__text-left{
  text-align: left;
}
