// VIU Social icons

.pane-viu-social{
  @include make-md-column(1);
  @include make-md-column-offset(10);
}

.viu-social{
  margin:0;
  padding:1em 0;
  clear:both;
  list-style-type: none;
  li{
    display:inline-block;
    margin-bottom:.75em;
  }
  a{
    transition:color 0.5s ease;
    &:hover{
      opacity:.7;
    }
  }

  .icon-fallback{
    display: none;
  }
}
.viu-social__socialIcon {
  margin-right:0.5em;
  font-size:20px;
  @include breakpoint("sm"){
    font-size:25px;
  }
}

.viu-social.reverse-color{
  a{
    color:white;
  }
}