.fullcalendar {

  .fc-event-time, .fc-content a:link, .fc-content a:visited {
    //color:#222;
  }

//  .fc-event-past .fc-event-title {
//    color:#FFF;
//  }

  .fc-event {
    margin-bottom: 4px;
    padding: 2px;
    line-height: 1.35em;
    font-size: 9pt;

    //background-color: #b8e3ec; //#ddd;
    //border-color: #b8e3ec; //#ddd;

    @include breakpoint('xs') {
      padding: 0px;
      font-size: 7pt;
    }
  }

  .fc-event-time {
    display:block;
  }

  .fc-agenda {
    .fc-event-time {
      display:none;
    }
  }

  .fc-view-basicDay {

    .fc-state-highlight {
      background: transparent;
    }

    .fc-event {
      padding: 2px;
      margin-bottom: 4px;
      line-height: 1.35em;
      font-size: 9pt;
    }
  }

  //*******
  // Header
  //*******

  .fc-header-title h2 {
    font-size: 18px;
  }

  @include breakpoint('xs') {

    .fc-header-left {
      float: left;
      margin-bottom: 10px;
    }

    .fc-header-center {
      display: block;
      float: left;
      clear: left;
    }

    .fc-header-right {
      float: left;
      clear: left;
      text-align:left;
    }
  }
}

//*******
// Open Atrium Calendar legend
//**************************************************************

.fullcalendar-legend{
  .fc-event a{
    &:focus, &:hover, &:active{
      color: inherit;
      text-decoration: none;
    }
    &:before{
      content:"\f058";
      font-family:FontAwesome;
      margin-right:.5em;
    }
  }
  .fc-event-hidden a:before{
    content:"\f1db";
  }
}


//*******
// Open Atrium Event List (Calendar block - oa-event overrides)
//**************************************************************

.view-oa-event-list .item-list li {
  list-style-type: none;
  margin-left: 0;
  line-height:1.3;
}
.oa-event-date-wrapper{
  height: auto;
  width: 45px;
  border: 0;
  background: #003f5f;
  color: white;
  padding:2px 0;
  margin: 0 10px 10px 0px;
}
.oa-event-date-month-wrapper{
  background: transparent;
  margin: 0;
  height: auto;
  line-height: 1.5em;
}
.oa-event-date-month{
  font-weight: normal;
}
.oa-event-date-day {
  font-size: 1.4em;
  font-weight: bold;
}