// VIU News and Events on homepage

// Title styles TBD after actual implementation

// Change grid to 12
// Set cols for nested grid calcs


.news_events_title{
  font-size:2em;
}

.viuEvents__event,
.viuNews__story{
  margin-bottom:2em;
}

.viuNews__story__heading, .viuEvents__event__heading{
  font-size: 1em;
  margin-top: 0;
  @include breakpoint('sm'){
    font-size:19px;
  }
  @include breakpoint('md'){
    font-size:22px;
  }
  a{
    text-decoration: none !important;
  }
}

#viuNewsEvents.page-section--color-1 {
  .viuNews__story__heading,
  .viuEvents__event__heading {
    a {
      color: white !important;
    }
  }
}
.content-container {
  .viuNews__story__heading,
  .viuEvents__event__heading {
    margin: 0 0 .5em 0;
  }
}

.viuNews__story__photo,
.viuEvents__event__dateicon{
  float:right;
  margin-left: 10px;
  max-width:100px;
}

.viuEvents__event__dateicon, .oa-event-date-wrapper{
  position: relative;
  background:transparent;
  border:0;
  height:60px;
  width:60px;
  padding:5px 0;
  border:3px solid;

  .oa-event-date-month-wrapper{
    background:transparent;
    margin:0 0 5px 0;
    line-height: 1em;
  }

  .oa-event-date-month{
    color:inherit;
  }

  .oa-event-date-day-wrapper,
  .oa-event-date-day{
    font-size:22px;
    font-weight:bold;
    line-height:0.9em;
  }
  br{
    display: none;
  }
}
.page-section--text-only.page-section--color-1, .page-section--text-only.page-section--color-2{
  .viuEvents__event__dateicon
  {
    border-color:white;
  }
}

/***
  News & Events in the sidebar.
 */

.news-teaser-sidebar, .events-teaser-sidebar{
  h3{
    font-size:14px;
  }
  p{
    font-size: 0.8em;
  }
}
