.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 500;
  width: 100%;
  //overflow-x: hidden;  //[viu mod]
  //overflow-y: auto; //[viu mod]
  background:$brand-primary;
}


.sidr.right {
  left: auto;
  right: -100%;
}

.sidr.left {
  left: -100%;
  right: auto
}


@include breakpoint("sm"){
  .sidr {
    width: 600px;
  }
  .sidr.right {
    left: auto;
    right: -600px
  }

  .sidr.left {
    left: -600px;
    right: auto
  }
}
@include breakpoint("lg"){
  .sidr {
    width: 700px;
  }
  .sidr.right {
    left: auto;
    right: -700px
  }

  .sidr.left {
    left: -700px;
    right: auto
  }
}

body.sidr-open {
  //position: fixed !important;
 }

#overlay {
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 102;
  display: none;
}

.sidr-open #overlay {
  display: block;
}
