/*Icons
------------------------------------*/
/*Social Icons*/

.social-icons {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin-right: 3px;
    margin-bottom: 5px;
    text-indent: -9999px;
    display: inline-block;

    a {
      width: 28px;
      height: 28px;
      display: block;
      background-position: 0 0;
      background-repeat: no-repeat;
      transition: all 0.3s ease-in-out;
    }
  }
}

a.social-icon {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.social-icons li:hover a {
  background-position: 0 -38px;
}

.social-icons-color li a {
  opacity: 0.7;
  background-position: 0 -38px !important;
  -webkit-backface-visibility: hidden;

  /*For Chrome*/

  &:hover {
    opacity: 1;
  }
}

.social_amazon {
  background: url(../images/icon/social/amazon.png) no-repeat;
}

.social_behance {
  background: url(../images/icon/social/behance.png) no-repeat;
}

.social_blogger {
  background: url(../images/icon/social/blogger.png) no-repeat;
}

.social_deviantart {
  background: url(../images/icon/social/deviantart.png) no-repeat;
}

.social_dribbble {
  background: url(../images/icon/social/dribbble.png) no-repeat;
}

.social_dropbox {
  background: url(../images/icon/social/dropbox.png) no-repeat;
}

.social_evernote {
  background: url(../images/icon/social/evernote.png) no-repeat;
}

.social_facebook {
  background: url(../images/icon/social/facebook.png) no-repeat;
}

.social_forrst {
  background: url(../images/icon/social/forrst.png) no-repeat;
}

.social_github {
  background: url(../images/icon/social/github.png) no-repeat;
}

.social_googleplus {
  background: url(../images/icon/social/googleplus.png) no-repeat;
}

.social_jolicloud {
  background: url(../images/icon/social/jolicloud.png) no-repeat;
}

.social_last-fm {
  background: url(../images/icon/social/last-fm.png) no-repeat;
}

.social_linkedin {
  background: url(../images/icon/social/linkedin.png) no-repeat;
}

.social_picasa {
  background: url(../images/icon/social/picasa.png) no-repeat;
}

.social_pintrest {
  background: url(../images/icon/social/pintrest.png) no-repeat;
}

.social_rss {
  background: url(../images/icon/social/rss.png) no-repeat;
}

.social_skype {
  background: url(../images/icon/social/skype.png) no-repeat;
}

.social_spotify {
  background: url(../images/icon/social/spotify.png) no-repeat;
}

.social_stumbleupon {
  background: url(../images/icon/social/stumbleupon.png) no-repeat;
}

.social_tumblr {
  background: url(../images/icon/social/tumblr.png) no-repeat;
}

.social_twitter {
  background: url(../images/icon/social/twitter.png) no-repeat;
}

.social_vimeo {
  background: url(../images/icon/social/vimeo.png) no-repeat;
}

.social_wordpress {
  background: url(../images/icon/social/wordpress.png) no-repeat;
}

.social_xing {
  background: url(../images/icon/social/xing.png) no-repeat;
}

.social_yahoo {
  background: url(../images/icon/social/yahoo.png) no-repeat;
}

.social_youtube {
  background: url(../images/icon/social/youtube.png) no-repeat;
}

.social_vk {
  background: url(../images/icon/social/vk.png) no-repeat;
}

.social_instagram {
  background: url(../images/icon/social/instagram.png) no-repeat;
}