#advisory, #covid-advisory {
  background: #ffc107;
  margin: 0 auto;
  padding: 10px 30px;
  color: #3c3c3c;
  z-index: 5;
  align-self: flex-start;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.25);

  a {
    color: #3c3c3c;
  }
  .cta-button{
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
    background: #e69519 !important;
    border-color: #e69519 !important;
    padding: 0.4em 0.75em;
    color: black;
    &:hover{
      background:#ffa41c !important;
      border-color: #ffa41c !important;
    }
  }
}
#covid-advisory {
  background: #0078B3;
  color:white;
  a{
    color:white;
  }
}
@include breakpoint('xs') {
  #advisory, #covid-advisory {
    width: calc( 100% + 30px);
    margin-left: -15px;
    padding: 10px 80px;
  }
}
.page-section.viu-covid{
  @include breakpoint("sm") {
    .page-section__content {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}