// Styles for desktop menu hint that reminds users
// where the menu is on first visit.
// --------------------------------------------------

.menu-hint {
  position: absolute;
  right: calc(100% + 40px);
  top: 160px;
  @media only screen and (max-width: 767px){
    right: calc(100% + 10px);
    top: 90px;
  }
  width: 250px;
  background: #fff;
  border:3px solid #BFD22B;
  border-radius: .4em;
  color: #0F3B5C;
  font-size: .6em;
  font-weight: bold;
  padding:1em 1em 3em 1em;
  a{
    color:white;
    display: block;
    position: absolute;
    bottom:1.25em;
    right:1.25em;
    background:#0078B3;
    font-size: .8em;
    padding: .25em .5em;
    font-weight: bold;
  }
}

.menu-hint:before {
  content: '';
  position: absolute;
  top: 0;
  left: 90%;
  width: 0;
  height: 0;
  border: 13px solid transparent;
  border-bottom-color: #BFD22B;
  border-top: 0;
  margin-left: -13px;
  margin-top: -14px;
}
.menu-hint:after {
  content: '';
  position: absolute;
  top: 0;
  left: 90%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  border-top: 0;
  margin-left: -10px;
  margin-top: -10px;
}